import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css'; // Reuse the Login.css styles

function SendResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendResetPassword = async () => {
    setMessage('');
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/send-reset-password`,
        { email }
      );
      setMessage(response.data.message); // Display success message
    } catch (error) {
      setError(
        error.response?.data.message || 'Failed to send reset password link. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Forgot Password</h2>
      <p>Enter your email address, and we will send you a link to reset your password.</p>

      <div className="form-group">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <button onClick={handleSendResetPassword} disabled={loading}>
        {loading ? 'Sending...' : 'Send Reset Password Link'}
      </button>

      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}

      <div className="forgot-password-link">
        <p>
          <Link to="/login">Back To Login</Link>
        </p>
      </div>
    </div>
  );
}

export default SendResetPassword;
