import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './Login.css'; // Reuse the existing styling

function ResetPassword() {
  const { token } = useParams(); // Retrieve the token from the URL
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setMessage('');
    setError('');
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password/${token}`,
        { password: newPassword },
        { headers: { 'Content-Type': 'application/json' } }
      );

      setMessage(response.data.message);
      navigate('/login'); // Redirect to login page after successful reset
    } catch (error) {
      setError(
        error.response ? error.response.data.message || 'Error resetting password' : 'An error occurred'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Set New Password</h2>
      <div className="form-group">
        <input
          type="password"
          placeholder="Enter your new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <button onClick={handleResetPassword} disabled={loading}>
        {loading ? 'Resetting...' : 'Reset Password'}
      </button>
      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default ResetPassword;
