import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import axios from 'axios';
import icon from './static/logo.png';

function Navbar({ isAuthenticated, userEmail, setIsAuthenticated, setUserEmail }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      // Send a POST request to the backend logout route
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`,
        {},
        { withCredentials: true } // Ensure cookies are included
      );

      // Clear authentication tokens or perform logout actions here
      setIsAuthenticated(false);
      setUserEmail('');
      localStorage.removeItem('userId');
      localStorage.removeItem('email');

      // Redirect to login page after logout
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <div>
      {/* Top Horizontal Navbar */}
      <div className="top-navbar">
        <div className="logo-container">
          <img src={icon} alt="App Icon" className="app-icon" />
          <h2 className="logo">ReCracked.net</h2>
        </div>

        <div className="dropdown">
          <button className="dropdown-button">
            {isAuthenticated ? userEmail || 'Account' : 'Account'}
          </button>
          <div className="dropdown-content">
            {isAuthenticated ? (
              <>
                <Link to="/profile">Your Account</Link>
                <button onClick={handleLogout}>Sign Out</button>
              </>
            ) : (
              <>
                <Link to="/login">Login</Link>
                <Link to="/register">Register</Link>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="left-navbar">
        <ul className="navbar-links">
          <li className={location.pathname === '/profile' ? 'active' : ''}>
            <Link to="/profile">
              <i className="fas fa-user"></i>
              <span>Profile</span>
            </Link>
          </li>
          <li className={location.pathname === '/subscription' ? 'active' : ''}>
            <Link to="/subscription">
              <i className="fas fa-dollar-sign"></i>
              <span>Subscription</span>
            </Link>
          </li>

          {/* Additional Links at the Bottom */}
          <div className="bottom-links">
            <li className={location.pathname === '/privacy-policy' ? 'active' : ''}>
              <Link to="/privacy-policy">
                <i className="fas fa-shield-alt"></i> {/* Icon for Privacy Policy */}
                <span>Privacy Policy</span>
              </Link>
            </li>
            <li className={location.pathname === '/terms-of-service' ? 'active' : ''}>
              <Link to="/terms-of-service">
                <i className="fas fa-file-alt"></i> {/* Icon for Terms of Service */}
                <span>Terms of Service</span>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
