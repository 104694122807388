import React from 'react';
import './styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p className="effective-date">Effective Date: 10/03/2024</p>
      <p>
        Welcome to ReCracked. We value your privacy and are committed to
        protecting your personal information. This Privacy Policy explains how
        we collect, use, disclose, and safeguard your information when you use
        our website www.recracked.net and the ReCracked Chrome Extension
        (collectively, the "Services"). By accessing or using our Services, you
        agree to the terms of this Privacy Policy.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>a. Personally Identifiable Information</h3>
      <p>
        <strong>Email Address:</strong> When you register for an account, we
        collect your email address to create and manage your account.
      </p>
      <p>
        <strong>Name (Optional):</strong> You may choose to provide your name
        for a personalized experience.
      </p>

      <h3>b. Authentication Information</h3>
      <p>
        <strong>Password:</strong> We collect a password when you create an
        account to secure your access to the Services.
      </p>
      <p>
        <strong>Authentication Tokens:</strong> We generate tokens to keep you
        logged in securely across sessions.
      </p>

      <h3>c. User Activity Data</h3>
      <p>
        <strong>Leetcode Problem Data:</strong> We track the problems you solve
        on Leetcode, including problem IDs, titles, difficulty levels, and
        timestamps of when you start and complete problems.
      </p>
      <p>
        <strong>Performance Metrics:</strong> We record the time taken to solve
        each problem to provide analytics and track improvement.
      </p>

      <h3>d. Web History</h3>
      <p>
        <strong>Visited URLs:</strong> We access the URLs of Leetcode pages you
        visit to detect when you're working on a problem.
      </p>
      <p>
        <strong>Interaction Data:</strong> Information about how you interact
        with Leetcode problems, such as attempts and completions.
      </p>

      <h3>e. Cookies and Similar Technologies</h3>
      <p>
        <strong>Cookies:</strong> We use cookies to store session information
        and remember your preferences.
      </p>
      <p>
        <strong>Local Storage:</strong> We may use browser local storage to
        cache data and improve performance.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        We use the collected information for the following purposes: Provide and
        Maintain Services, Enhance User Experience, Performance Analytics,
        Communication, and Security.
      </p>

      <h2>3. Data Sharing and Disclosure</h2>
      <p>
        We do not sell, trade, or rent your personal information to third
        parties. We may share your information with service providers and for
        legal requirements or protection of rights.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We implement various security measures, including encryption and access
        controls, to maintain the safety of your personal information.
      </p>

      <h2>5. Data Retention</h2>
      <p>
        We retain your personal information as long as your account is active or
        as needed to provide the Services. You can request deletion by
        contacting us.
      </p>

      <h2>6. Your Rights and Choices</h2>
      <p>
        Access and update your personal information through your account Profile
        and request deletion by contacting us.
      </p>

      <h2>7. Children's Privacy</h2>
      <p>
        Our Services are not intended for individuals under the age of 13. We do
        not knowingly collect personal information from children under 13.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Please review it
        periodically for any updates.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns, please contact us at:
        <br />
        <strong>Email:</strong> contact@recracked.net
      </p>

      <p>By using our Services, you acknowledge that you have read and understand this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
