import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Landing from './pages/Landing';
import Profile from './pages/Profile';
import Subscription from './pages/Subscription';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Login from './components/Login';
import SendResetPassword from './components/SendResetPassword';
import Register from './components/Register';
import Navbar from './components/Navbar';
import ResetPassword from './components/ResetPassword';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // Check if the user is authenticated (only if a session or token exists)
  useEffect(() => {
    const checkAuthStatus = async () => {
      const userId = localStorage.getItem('userId');
      if (userId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/check-auth`, { withCredentials: true });
          if (response.data.isAuthenticated) {
            setIsAuthenticated(true);
            setUserEmail(response.data.email);
            localStorage.setItem('userId', response.data.userId);
            localStorage.setItem('email', response.data.email);
          } else {
            setIsAuthenticated(false);
            setUserEmail('');
            localStorage.removeItem('userId');
            localStorage.removeItem('email');
          }
        } catch (error) {
          console.error('Error checking authentication status:', error);
          setIsAuthenticated(false);
          setUserEmail('');
          localStorage.removeItem('userId');
          localStorage.removeItem('email');
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  return (
    <>
      <Navbar isAuthenticated={isAuthenticated} userEmail={userEmail} setIsAuthenticated={setIsAuthenticated} setUserEmail={setUserEmail} />
      <Routes>
        {/* Landing Page for unauthenticated users */}
        <Route path="/" element={isAuthenticated ? <Navigate to="/profile" /> : <Landing />} />

        {/* Authenticated-only pages */}
        <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/subscription" element={isAuthenticated ? <Subscription /> : <Navigate to="/login" />} />

        {/* Public pages */}
        <Route path="/login" element={
          <Login
            setIsAuthenticated={setIsAuthenticated}
            setUserEmail={setUserEmail}
          />
        } />
        <Route path="/send-reset-password" element={<SendResetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </>
  );
}

export default App;
