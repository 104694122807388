import React from 'react';
import './styles/Landing.css';
import logo from '../components/static/logo.png';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="landing-container">

      {/* Headline */}
      <h2 className="headline">
        <em>Never</em> Forget a Leetcode Solution Again – <strong>Boost</strong> Your Retention with ReCracked!
      </h2>

      <p className="subtext">Ebbinghaus’s Forgetting Curve</p>

      {/* Body Content */}
      <p className="body-text">
        "Without <strong>REPETITION</strong>, people forget <span className="highlight">56%</span> of new information within an hour, <span className="highlight">66%</span> after a day, and <span className="highlight">75%</span> after only <strong>6 Days</strong>!!!"
      </p>

      <p className="description">
        ReCracked is your ultimate memory hack for Leetcode problems. It uses science-backed spaced repetition techniques to ensure you remember crucial techniques and patterns to get you interview-ready.
      </p>

      {/* Call-to-Action Button */}
      <button className="cta-button" onClick={handleLoginRedirect}>
        Claim Your 7-Day Free Trial Today
      </button>
    </div>
  );
};

export default Landing;
