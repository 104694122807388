import React from 'react';
import './styles/TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p className="effective-date">Effective Date: 10/03/2024</p>
      <p>
        Welcome to ReCracked! These Terms of Service ("Terms") govern your access to and use of our website www.recracked.net and the ReCracked Chrome Extension (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Services.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By creating an account or using our Services, you affirm that you are at least 13 years old and capable of entering into a legally binding agreement. If you are using the Services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.
      </p>

      <h2>2. Description of Services</h2>
      <p>
        ReCracked provides tools to enhance your learning experience on Leetcode by tracking solved problems and scheduling reviews using spaced repetition techniques. The Services are designed to help you improve your coding skills and retention of problem-solving methods.
      </p>

      <h2>3. User Accounts</h2>
      <h3>a. Registration</h3>
      <p>
        <strong>Account Creation:</strong> To access certain features, you must create an account by providing a valid email address and creating a password.
      </p>
      <p>
        <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
      </p>
      <p>
        <strong>Accurate Information:</strong> You agree to provide accurate and complete information during registration and to keep it updated.
      </p>

      <h3>b. Account Termination</h3>
      <p>
        <strong>Your Rights:</strong> You may terminate your account at any time by simply deleting your account from the profile page.
      </p>
      <p>
        <strong>Our Rights:</strong> We reserve the right to suspend or terminate your account if you violate these Terms or engage in fraudulent or harmful activities.
      </p>

      <h2>4. Acceptable Use</h2>
      <h3>a. Prohibited Activities</h3>
      <p>You agree not to:</p>
      <ul>
        <li>Use the Services for any illegal or unauthorized purpose.</li>
        <li>Access or attempt to access other users' accounts without permission.</li>
        <li>Disrupt or interfere with the security or performance of the Services.</li>
        <li>Reverse engineer or decompile any part of the Services.</li>
      </ul>

      <h3>b. User Content</h3>
      <p>
        <strong>Ownership:</strong> You retain ownership of any content you submit through the Services, such as notes or annotations.
      </p>
      <p>
        <strong>License:</strong> By submitting content, you grant us a non-exclusive, royalty-free license to use, reproduce, and display the content as necessary to provide the Services.
      </p>

      <h2>5. Intellectual Property</h2>
      <h3>a. Our Content</h3>
      <p>
        <strong>Ownership:</strong> All materials provided through the Services, including text, graphics, logos, and software, are owned by us or our licensors and are protected by intellectual property laws.
      </p>
      <p>
        <strong>License:</strong> We grant you a limited, non-transferable license to access and use the Services for personal, non-commercial purposes.
      </p>

      <h3>b. Restrictions</h3>
      <p>You may not:</p>
      <ul>
        <li>Copy, modify, or create derivative works of the Services.</li>
        <li>Use any automated systems (e.g., robots or spiders) to access the Services.</li>
      </ul>

      <h2>6. Privacy Policy</h2>
      <p>
        Your use of the Services is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal information. By using the Services, you consent to the practices outlined in the Privacy Policy.
      </p>

      <h2>7. Third-Party Services</h2>
      <p>
        The Services may contain links to third-party websites or resources, such as Leetcode. We are not responsible for the content, products, or services on these sites. Your interactions with third-party sites are governed by their own terms and policies.
      </p>

      <h2>8. Disclaimers</h2>
      <p>
        No Warranty: The Services are provided "as is" without warranties of any kind, either express or implied. We do not guarantee that the Services will be uninterrupted or error-free.
      </p>
      <p>
        Educational Purposes: The Services are intended for educational purposes. We do not guarantee any specific results from using the Services.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law:</p>
      <p>
        Indirect Damages: We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Services.
      </p>
      <p>
        Total Liability: Our total liability to you for any claims arising from the Services shall not exceed the amount you have paid us, if any, in the last twelve months.
      </p>

      <h2>10. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless ReCracked and its affiliates, officers, agents, and employees from any claims, liabilities, damages, or expenses arising from your use of the Services or violation of these Terms.
      </p>

      <h2>11. Changes to the Terms</h2>
      <p>
        We may update these Terms from time to time. We will notify you of significant changes by posting the new Terms on this page with an updated effective date. Your continued use of the Services after any changes constitutes your acceptance of the new Terms.
      </p>

      <h2>12. Governing Law</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law provisions.
      </p>

      <h2>13. Dispute Resolution</h2>
      <p>
        Any disputes arising out of or relating to these Terms or the Services shall be resolved through binding arbitration in accordance with the rules of [Insert Arbitration Organization], rather than in court, except for matters that may be taken to small claims court.
      </p>

      <h2>14. Severability</h2>
      <p>
        If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions will remain in full force and effect.
      </p>

      <h2>15. Entire Agreement</h2>
      <p>
        These Terms, along with our Privacy Policy, constitute the entire agreement between you and ReCracked regarding the use of the Services and supersede any prior agreements.
      </p>

      <h2>16. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact us at:
        <br />
        <strong>Email:</strong> contact@recracked.net
      </p>

      <p>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
    </div>
  );
};

export default TermsOfService;
