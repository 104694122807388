import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Profile.css';
import axios from 'axios';

function Profile() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-auth`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          fetchUserData();
          fetchSubscriptionDetails();
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
        navigate('/login'); // Redirect to login page if not authenticated
      }
    };
  
    checkAuthStatus();
  }, [navigate]);

  // Fetch user data (name, email)
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile/user-data`, { withCredentials: true });
      const { name, email } = response.data;
      setName(name);
      setEmail(email);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data');
    }
  };

  // Fetch subscription details from the backend
  const fetchSubscriptionDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile/subscription-details`, { withCredentials: true });
      setSubscription(response.data);
    } catch (error) {
      console.error('Error fetching subscription data:', error);
      setError('Failed to fetch subscription details');
    }
  };

  // Update the user's name
  const handleUpdateName = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile/update-name`, { name }, { withCredentials: true });
      alert('Name updated successfully!');
    } catch (error) {
      console.error('Error updating name:', error);
      setError('Failed to update name');
    }
  };

  // Update the user's password
  const handleUpdatePassword = async () => {
    if (!password) {
      alert('Please enter a new password.');
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/profile/update-password`, { password }, { withCredentials: true });
      alert('Password updated successfully!');
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to update password');
    }
  };

  // Delete the user's account
  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action is irreversible.')) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/auth/delete-account`, { withCredentials: true });
        alert('Your account has been deleted.');
        navigate('/login');
      } catch (error) {
        console.error('Error deleting account:', error);
        setError('Failed to delete account');
      }
    }
  };

  // Cancel subscription
  const cancelSubscription = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/cancel-subscription`, {}, { withCredentials: true });
      alert('Subscription cancellation scheduled at period end.');
      // Refresh subscription details
      fetchSubscriptionDetails();
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription');
    }
  };

  // Capitalize the first letter of strings
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (loading) {
    return <div>Loading profile...</div>;
  }

  // Determine the appropriate button text and action based on subscription status
  const renderSubscriptionButton = () => {
    if (subscription && subscription.subscriptionType === 'free_trial') {
      // Free trial: Show "Upgrade Subscription"
      return (
        <button className="subscription-btn" onClick={() => navigate('/subscription')}>
          Upgrade Plan
        </button>
      );
    } else if (subscription && subscription.activeSubscription) {
      // Active subscription: Show "Cancel Subscription"
      return (
        <button className="subscription-btn cancel-subscription" onClick={cancelSubscription}>
          Cancel Plan
        </button>
      );
    } else {
      // No active subscription or trial: Show "Get Subscription Plan"
      return (
        <button className="subscription-btn" onClick={() => navigate('/subscription')}>
          Get Subscription
        </button>
      );
    }
  };

  return (
    <div className="profile-container">
      <h2>Profile</h2>
      {error && <div className="error-message">{error}</div>}
      <div className="profile-info">
        <div className="input-group">
          <label>Name</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          <button className="update-btn" onClick={handleUpdateName}>
            Update Name
          </button>
        </div>
        <div className="input-group">
          <label>Email</label>
          <input type="email" value={email} disabled />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter new password"
          />
          <button className="create-btn" onClick={handleUpdatePassword}>
            Update Password
          </button>
        </div>
      </div>

      <div className="subscription-section">
        {subscription && (
          <div className="subscription-info">
            {subscription.activeSubscription ? (
              <>
                <p>
                  Subscription Type:{' '}
                  {subscription.subscriptionType
                    ? capitalizeFirstLetter(subscription.subscriptionType)
                    : 'Unknown'}
                </p>
                {subscription.subscriptionEndDate && (
                  <p>
                    Ends On: {new Date(subscription.subscriptionEndDate).toLocaleDateString()}
                  </p>
                )}
              </>
            ) : subscription.trial ? (
              <>
                <p>Subscription Type: Free Trial</p>
                <p>
                  Trial Ends On: {new Date(subscription.trialEndDate).toLocaleDateString()}
                </p>
              </>
            ) : (
              <p>No Active Plan.</p>
            )}
          </div>
        )}

        {/* Render the appropriate subscription button */}
        {renderSubscriptionButton()}
      </div>

      <div className="delete-account">
        <div className="delete-section">
          <button className="delete-btn" onClick={handleDeleteAccount}>
            Delete Account
          </button>
          <p>All your data will be permanently deleted!</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;
