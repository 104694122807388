import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import './styles/Subscription.css';
import { useNavigate } from 'react-router-dom';

// Initialize Stripe with your Publishable Key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Subscription() {
  const [subscriptionStatus, setSubscriptionStatus] = useState({
    activeSubscription: false,
    subscriptionType: null,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // Default to false
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Check if user is authenticated on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-auth`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          fetchSubscriptionStatus();  // Only fetch subscription details if authenticated
        }
      } catch (error) {
        setIsAuthenticated(false);  // If not authenticated, allow viewing of plans but no subscription status is fetched
      }
    };
    checkAuthStatus();
  }, []);

  // Fetch subscription status if the user is authenticated
  const fetchSubscriptionStatus = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile/subscription-details`, { withCredentials: true });
      if (response.data) {
        setSubscriptionStatus({
          activeSubscription: response.data.activeSubscription,
          subscriptionType: response.data.subscriptionType,
        });
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setError('Failed to fetch subscription status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle checkout, requiring login if not authenticated
  const handleCheckout = async (priceId, isRecurring, subscriptionType) => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      navigate('/login');
      return;
    }

    try {
      setLoading(true);
      // Make request to create Stripe Checkout session
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-checkout-session`,
        { priceId, isRecurring, subscriptionType },
        { withCredentials: true } // Ensure session cookies are sent with the request
      );

      if (response.data && response.data.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });

        if (error) {
          console.error('Stripe Checkout error:', error);
          alert('Checkout failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      alert('Checkout failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="subscription-container">
      <h2>Subscription Plans</h2>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <div className="plans-container">
        {/* Monthly Plan */}
        <div className="plan-card">
          <h3>Monthly Plan</h3>
          <ul>
            <li>✓ Support Leetcode</li>
            <li>✓ Reminders to Review</li>
            <li>✓ No Limit to Problems Tracked</li>
            <li>✓ Track Time Spent Solving</li>
            <li>✓ Show Your Learning Progress</li>
            <li>✓ Access To Future Features</li>
          </ul>
          <button
            className={subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'monthly'
              ? 'plan-button-disabled'
              : 'plan-button'}
            onClick={() => handleCheckout('price_1Q6GC6Fd9yeR6VrNOXhk2mGt', true, 'monthly')}
            disabled={loading || (subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'monthly')}
          >
            {subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'monthly'
              ? 'Current Plan'
              : 'Get Monthly Plan'}
          </button>
          <div className="price">$4.99</div>
        </div>

        {/* Annual Plan */}
        <div className="plan-card">
          <h3>Annual Plan</h3>
          <ul>
            <li>✓ Support Leetcode</li>
            <li>✓ Reminders to Review</li>
            <li>✓ No Limit to Problems Tracked</li>
            <li>✓ Track Time Spent Solving</li>
            <li>✓ Show Your Learning Progress</li>
            <li>✓ Access To Future Features</li>
          </ul>
          <button
            className={subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'annual'
              ? 'plan-button-disabled'
              : 'plan-button'}
            onClick={() => handleCheckout('price_1Q6GCtFd9yeR6VrNL3nQUY58', true, 'annual')}
            disabled={loading || (subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'annual')}
          >
            {subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'annual'
              ? 'Current Plan'
              : 'Get Annual Plan'}
          </button>
          <div className="price">
            <span className="old-price">$59.88</span>
            $45.99
          </div>
        </div>

        {/* Lifetime Plan */}
        <div className="plan-card">
          <h3>Lifetime Access</h3>
          <ul>
            <li>✓ Support Leetcode</li>
            <li>✓ Reminders to Review</li>
            <li>✓ No Limit to Problems Tracked</li>
            <li>✓ Track Time Spent Solving</li>
            <li>✓ Show Your Learning Progress</li>
            <li>✓ Access To Future Features</li>
          </ul>
          <button
            className={subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'lifetime'
              ? 'plan-button-disabled'
              : 'plan-button'}
            onClick={() => handleCheckout('price_1Q6GDZFd9yeR6VrNUdBt8WW4', false, 'lifetime')}
            disabled={loading || (subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'lifetime')}
          >
            {subscriptionStatus.activeSubscription && subscriptionStatus.subscriptionType === 'lifetime'
              ? 'Current Plan'
              : 'Get Lifetime Access'}
          </button>
          <div className="price">
            <span className="old-price">$99.99</span>
            $68.99
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
